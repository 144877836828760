import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HoverProduct from "./HoverProduct";
function Navbar1() {
  const [products, showproducts] = useState(true);
  return (
    <div className="">
      <nav
        // style={{ backgroundColor: "black" }}
        className="navbar navbar-expand-lg navbar-light"
      >
        <Link className="navbar-brand logo " to="/">
          <img src="Images/logo2.png" style={{ height: "80px" }} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto flex col justify-around ">
            <li className="nav-item active">
              <Link
                to="/"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "start" })
                }
                className="nav-Links nav-link"
                style={{ textDecoration: "none  " }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item active">
              <Link
                to="/about"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "start" })
                }
                className="nav-Links nav-link"
                style={{ textDecoration: "none  " }}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item active dropdown">
              <a
                className=" nav-Links nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Our Products
              </a>
              <div class="dropdown-menu " aria-labelledby="navbarDropdown">
                <div className="hoverul">
                  <Link className="Hover-Links " to="/Fungicide">
                    {" "}
                    <div className="hoverli">Fungicides</div>
                  </Link>
                  <Link className="Hover-Links " to="/Insecticides">
                    {" "}
                    <div className="hoverli">Insecticide</div>
                  </Link>
                  <Link className="Hover-Links " to="/Herbicide">
                    {" "}
                    <div className="hoverli">Herbicide</div>
                  </Link>
                  <Link className="Hover-Links" to="/PGP">
                    {" "}
                    <div className="hoverli ">Plant Growth Promoter</div>
                  </Link>
                  {/* <Link className="Hover-Links " to="/Fertilizer">
                    {" "}
                    <div className="hoverli">Fertilizer</div>
                  </Link> */}
                </div>
              </div>
            </li>
            <li className="nav-item active">
              <Link
                to="/home#footer"
                scroll={(el) =>
                  el.scrollIntoView({ behavior: "smooth", block: "start" })
                }
                className="nav-Links nav-link"
                style={{ textDecoration: "none  " }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar1;
