import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Navbar1 from "./Components/Navbar1";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Home2 from "./Components/Home2";
import Sec2 from "./Components/Sec2";
import Popular from "./Components/Popular";
import Aboutus from "./Components/Aboutus";
import Footer from "./Components/Footer";
import Popular2 from "./Components/Popular2";
import Insecticides from "./Components/Products/Insecticides";
import Fungicide from "./Components/Products/Fungicide";
import Fertlilizer from "./Components/Products/Fertlilizer";
import PGP from "./Components/Products/PGP";
import Herbicide from "./Components/Products/Herbicide";
import N from "./Components/N";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function analytics() {
  ReactGA.initialize("UA-249968326-1");
  ReactGA.pageview("Home");
  ReactGA.pageview("Insecticides");
  ReactGA.pageview("Fungicide");
  ReactGA.pageview("PGP");
  ReactGA.pageview("Herbicide");
  ReactGA.pageview("About");
}

//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  analytics();
  return (
    <div className="App">
      <Router>
        <Header />
        <Navbar1 />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Insecticides" element={<Insecticides />} />
          <Route exact path="/Fungicide" element={<Fungicide />} />
          {/* <Route exact path="/Fertilizer" element={<Fertlilizer />} /> */}
          <Route exact path="/PGP" element={<PGP />} />
          <Route exact path="/Herbicide" element={<Herbicide />} />
          <Route exact path="/About" element={<Aboutus />} />
        </Routes>
        {/* <BrowserRouter>
          <Switch>
            <Route path="/" component={Home}></Route>
          </Switch>
        </BrowserRouter> */}
        {/* <Home2></Home2> */}
        {/* <Sec2 />
        <Aboutus />
        
        <Popular2></Popular2> */}
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
