import React from "react";
import "../../Styles/Productspage.css";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
import ProductPage from "./ProductPage";
import { InsecticidesList } from "../List/InsecticidesList";
function Insecticides() {
  return (
    <div className="Insect">
      <ProductPage Title={"Insecticides"} />
      <div>
        {" "}
        {InsecticidesList.map((item) => {
          return (
            <ProductCard
              id={item._id}
              imgurl={item.imgurl}
              Name={item.Name}
              Technicalname={item.TechnicalName}
              Type={item.Type}
              Crops={item.Crops}
              Usage={item.Usage}
              Packing={item.Packing}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Insecticides;
