import React from "react";
import "../Styles/Nav.css";
function Header() {
  return (
    <div className="header ">
      <div className=" headerdiv flex items-center">
        <div className="phoneimg ">
          <img src="Images/header.png" alt="" />
        </div>
      </div>
      <div className="Inquirybtn">
        <button>Send Inquiry</button>
      </div>
    </div>
  );
}

export default Header;
