import React from "react";
import { FungicideList } from "../List/FungicideList";
import ProductPage from "./ProductPage.js";
import ProductCard from "./ProductCard.js";
import ProductCard2 from "./ProductCard2.js";

function Fungicide() {
  return (
    <div>
      <ProductPage Title={"Fungicides"} />
      <div>
        {" "}
        {FungicideList.map((item) => {
          return (
            <ProductCard
              id={item._id}
              imgurl={item.imgurl}
              Name={item.Name}
              Technicalname={item.TechnicalName}
              Type={item.Type}
              Crops={item.Crops}
              Usage={item.Usage}
              Packing={item.Packing}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Fungicide;
