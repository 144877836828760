import React from "react";
import "../../Styles/Productspage.css";
import { Link } from "react-router-dom";
function ProductPage({ Title }) {
  return (
    <div className="Page">
      <section class="page-title">
        <div class="auto-container " style={{ zIndex: 1 }}>
          <h1 style={{ fontFamily: "Lato" }}>{Title}</h1>
        </div>
      </section>
      <section className="info" style={{ fontSize: "18px" }}>
        <ul>
          <li>
            {" "}
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              Home
            </Link>{" "}
          </li>
          <li style={{ color: "white" }}>
            {" "}
            <span
              className=" fa fa-solid fa-angle-right  "
              style={{ fontSize: "15px" }}
            ></span>
          </li>
          <li style={{ color: "#ffcc2c" }}>{Title}</li>
        </ul>
      </section>
      <h1 className="h1">{Title}</h1>
      <img
        className="Under-img"
        src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
        alt=""
      />
    </div>
  );
}

export default ProductPage;
