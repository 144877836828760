import React from "react";
import { HerbicideList } from "../List/HerbicidesList";
import ProductPage from "./ProductPage.js";
import ProductCard from "./ProductCard.js";

function Herbicide() {
  return (
    <div>
      <ProductPage Title={"Herbicides"} />
      <div>
        {" "}
        {HerbicideList.map((item) => {
          return (
            <ProductCard
              id={item._id}
              imgurl={item.imgurl}
              Name={item.Name}
              Technicalname={item.TechnicalName}
              Type={item.Type}
              Crops={item.Crops}
              Usage={item.Usage}
              Packing={item.Packing}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Herbicide;
