export const InsecticidesList = [
  {
    _id: 1,
    Name: "Maxx Ultra",
    imgurl: "ProductImg/Maxx-Ultra.png",
    TechnicalName: "Fipronil 0.6% GR",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Wheat.",
    Packing: "5kg",
    Usage:
      "Controls stem borer & leaf folder in paddy, act as plant growth enhancer.",
  },
  {
    _id: 2,
    Name: "Volvo",
    imgurl: "ProductImg/Volvo.png",
    TechnicalName: "Organic Manure",
    Type: "Insecticide & P.G.R",
    Crops: "Paddy, Sugarcane.",
    Packing: "5kg & 25kg",
    Usage: "Controls stem borer & early shoot borer, promotes root development",
  },
  {
    _id: 3,
    Name: "Imperial",
    imgurl: "ProductImg/Imperial.png",
    TechnicalName: "Thiamethoxam 0.9% + Fipronil 0.2% Gr",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Vegetables, Wheat.",
    Packing: "5Kg, 4kg.",
    Usage:
      "Controls insect pests, including, whiteflies, beetles, thrips, leafhoppers, and termites.",
  },
  {
    _id: 4,
    Name: "Suncron",
    imgurl: "ProductImg/Suncron.png",
    TechnicalName: "Profenophos 50% EC",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane.",
    Packing: " 1ltr, 500ml.",
    Usage:
      "Effective control on all sucking pests and mites on all types of crops.",
  },
  {
    _id: 5,
    Name: "Tiger-SP",
    imgurl: "ProductImg/Tiger-SP.png",
    TechnicalName: "Cartap Hydrochloride 50% S.P",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane.",
    Packing: "500gm, 250gm, 100gm.",
    Usage: "Controls stem borer, leaf folder.",
  },
  {
    _id: 6,
    Name: "Tiger-G",
    imgurl: "ProductImg/Tiger-G.jpg",
    TechnicalName: "Cartap Hydrochloride 4% G.R",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Wheat, Vegetables.",
    Packing: "5kg",
    Usage: "Controls leaf folder, stem borer, whorl maggot.",
  },
  {
    _id: 7,
    Name: "Rustom",
    imgurl: "ProductImg/Rustam.png",
    TechnicalName: "Thiamethoxam (12.6%) + Lambdacyhalothrin (9.5%)",
    Type: "Insecticide",
    Crops: "Paddy,Sugarcane,Mango.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Prevents sucking pest & caterpillars,promotes crop greening.",
  },
  {
    _id: 8,
    Name: "Sun Tara-Pro",
    imgurl: "ProductImg/Tara_pro.png",
    TechnicalName: "Thiamethoxam 30% F.S",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Wheat, Cotton, Maize.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Prevents early sucking & chewing pests,used to treat seeds.",
  },
  {
    _id: 9,
    Name: "Agni-5",
    imgurl: "ProductImg/agni.png",
    TechnicalName: "Emamectin Benzoate 5% SG",
    Type: "Insceticide",
    Crops: "Paddy, Sugarcane, Mango, Cotton, Cabbage, Chilli.",
    Packing: "1Kg, 500gm, 250gm, 100gm, 50gm.",
    Usage: "Prevents fruit & shoot borer,thrips, mites,pod borer.",
  },
  {
    _id: 10,
    Name: "Farsa",
    imgurl: "ProductImg/Farsa.png",
    TechnicalName: "Alphacypermethrin 10% SC",
    Type: "Insecticide",
    Crops: "Fruits, Vegetables, Sugarcane, Flower crops.",
    Packing: "1Ltr, 500ml,250ml.",
    Usage: "Prevent mosquitoes, house flies, cockroach.",
  },
  {
    _id: 11,
    Name: "Sun Tara",
    imgurl: "ProductImg/SunTara.png",
    TechnicalName: "Thiamethoxam 25% WG",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Wheat, Vegetables.",
    Packing: "1kg, 500gm, 250gm, 100gm.",
    Usage:
      "Prevent stem borer, plant hopper,thrips, aphids, jassids and whitefly.",
  },
  {
    _id: 12,
    Name: "Rudra-44",
    imgurl: "ProductImg/Rudra-44.png",
    TechnicalName: "Profenofos 40% + Cypermethrin 4% EC",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Cotton.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: " Prevents sucking pests,bollworm complex, thrips, whiteflies.",
  },
  {
    _id: 13,
    Name: "Nagada",
    imgurl: "ProductImg/nagada.png",
    TechnicalName: "Lambda-Cyhalothrin 5% EC",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Wheat, Vegetables.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Prevent shoot & fruit borer, thrips, mite, leaf hopper.",
  },
  {
    _id: 14,
    Name: "Thunder",
    imgurl: "ProductImg/Thunder.png",
    TechnicalName: "Lambda-Cyhalothrin 4.9% CS",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Wheat, Brinjal, Chilli.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Prevent bollworms, stemborer, leaf folder, thrips.",
  },
  {
    _id: 15,
    Name: "Kohinoor",
    imgurl: "ProductImg/Kohinoor.png",
    TechnicalName: "Imidacloprid 70% WG",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Wheat.",
    Packing: "150gm, 300gm.",
    Usage:
      "Control sucking insects, termites, some soil insects, & fleas on pets.",
  },
  {
    _id: 16,
    Name: "Killer",
    imgurl: "ProductImg/Killer.png",
    TechnicalName: "Cypermethrin 25% EC",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Cotton.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Prevent cotton bollworms, jassids, thrips, shoot & fruit borer.",
  },

  {
    _id: 17,
    Name: "Black Tiger",
    imgurl: "ProductImg/Black Tiger.png",
    TechnicalName: "Buprofenzin 25% SC",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Vegetables",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage:
      "Prevent brown hopper on paddy,leaf hopper & whitefly on vegetable crops",
  },
  {
    _id: 18,
    Name: "Baaz 505",
    imgurl: "ProductImg/Baaz.png",
    TechnicalName: "Chlorpyriphos 50% + Cypermethrin 5% EC",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane ,Mango, Guava.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Controls sucking & chewing pests.",
  },

  {
    _id: 19,
    Name: "Maxx",
    imgurl: "ProductImg/Maxx.png",
    TechnicalName: "Fipronil 5% S.C",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Mango, Cotton.",
    Packing: "1Ltr,500ml,250ml,100ml.",
    Usage: "Controls stem borer, leaf folder, root borer, thrips, aphid.",
  },
  {
    _id: 20,
    Name: "Maxx-G",
    imgurl: "ProductImg/Maxx-G.jpg",
    TechnicalName: "Fipronil 0.3% G.R",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Wheat.",
    Packing: "5kg",
    Usage: "Controls stem borer, brown plant hopper, green leaf hopper.",
  },

  {
    _id: 21,
    Name: "Attack",
    imgurl: "ProductImg/Attack.png",
    TechnicalName: "Acephate 75% S.P",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Mango, Vegetables.",
    Packing: "1Kg, 500gm, 250gm, 100gm.",
    Usage: "Controls sucking and biting insects.",
  },
  {
    _id: 22,
    Name: "Dollar",
    imgurl: "ProductImg/Dollar.png",
    TechnicalName: "Acetamiprid 20% W.P",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Vegetables.",
    Packing: "100gm.",
    Usage: "Controls thrips, whitefly, leafhoppers & other insects.",
  },
  {
    _id: 23,
    Name: "Toofan",
    imgurl: "ProductImg/Toofan.png",
    TechnicalName: "Fenobucarb (BPMC) 50% E.C",
    Type: "Insecticide",
    Crops: "Paddy, Mango.",
    Packing: "1ltr, 500ml.",
    Usage:
      "Most effective insecticide for control of leafhoppers and plant hoppers.",
  },
  {
    _id: 24,
    Name: "Fighter Gold(G.R)",
    imgurl: "ProductImg/Fighter-GR.png",
    TechnicalName: "Chlorantraniliprole 0.4% GR  ",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane.",
    Packing: "4Kg.",
    Usage:
      "Controls Early Shoot Borer and Top Borer pests in Paddy, Sugarcane crop.",
  },
  {
    _id: 25,
    Name: "Fighter Gold",
    imgurl: "ProductImg/Fighter-Gold.png",
    TechnicalName: "Chlorantraniliprole 18.5% w/w ",
    Type: "Insecticide",
    Crops: "Paddy, Sugarcane, Vegetables.",
    Packing: "150ml, 60ml, 30ml.",
    Usage:
      "Controls moth and butterfly caterpillars or larvae, as well as some beetles.",
  },
  {
    _id: 26,
    Name: "Diamond Plus",
    imgurl: "ProductImg/Diamond.png",
    TechnicalName: "Imidacloprid 40% + Fipronil 40% w/w",
    Type: "Insecticide",
    Crops: "Sugarcane.",
    Packing: "100gm.",
    Usage: "Controls soil insects, white grub of sugarcane.",
  },
];
