import React from "react";
import "../Styles/Footer.css";
import Map1 from "./Map1";
import { Link } from "react-router-dom";
<style></style>;
function Footer() {
  return (
    <div className="footer row" id="footer">
      {/* <div className="col-md-5">
        <div className="ml-25">
          <a href="#">
            <img src="Images/logo2.png" className="imgftr " alt="" />
          </a>
        </div>
        <span className="textftr">
          Sun Agro Chemicals is one of leading Co engaged in manufacturing
          insecticides, Weedicides, Herbicides, Fungicides, Plant growth
          regulators, Fertilizers, House Hold having separate formulation
          section with latest plant and machinery where we can make better
          quality products
        </span>
      </div> */}

      <div className="links col-md-3 align-items-center">
        <h1>Quick Links</h1>
        <img
          src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
          alt=""
        />
        <div>
          <ul>
            <li>
              {" "}
              <span className=" fas fa-solid fa-angle-right"></span>
              <Link to="/" className="lin">
                Home
              </Link>
            </li>
            <li>
              {" "}
              <span className=" fas fa-solid fa-angle-right"></span>
              <Link to="/Aboutus" className="lin">
                About Us
              </Link>
            </li>
            <li>
              {" "}
              <span className=" fas fa-solid fa-angle-right"></span>
              <Link to="/" className="lin">
                Our Products
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="contact col-md-6">
        <h1>Contact Us</h1>
        <img
          src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
          alt=""
        />

        <h3>Sun Agro Chemicals</h3>
        <div className="loc">
          <a
            style={{ height: "20px" }}
            href="https://www.google.com/maps/place/Sun+Agro+Chemicals/@29.687242,77.0411758,11z/data=!4m19!1m13!4m12!1m4!2m2!1d77.2749958!2d29.7830938!4e1!1m6!1m2!1s0x390e7ba99d001ab3:0x8d4c3c6127a1777b!2ssun+agro+chemicals!2m2!1d77.0875264!2d29.6263738!3m4!1s0x390e7ba99d001ab3:0x8d4c3c6127a1777b!8m2!3d29.6263738!4d77.0875264"
          >
            <img
              style={{ height: "20px", margin: "4px 20px 0px 0px" }}
              src="/Images/placeholder (1).png"
              alt=""
            />
          </a>
          <p>
            12th Milestone, Karnal-Merrut Road, Village Andhera, <br />
            Karnal-132001, Haryana, India <br />
          </p>
        </div>
        <div className="loc">
          <a href="tel:+917895312653">
            <img
              style={{ height: "20px", margin: "4px 20px 0px 0px" }}
              src="/Images/telephone.png"
              alt=""
            />
          </a>
          <p>7895312653</p>
        </div>
        <div className="loc">
          <a>
            <img
              style={{ height: "20px", margin: "4px 25px 0px 0px" }}
              src="/Images/email.png"
              alt=""
            />
          </a>
          <p>sunagrochemicals21@gmail.com</p>

          {/* <h1>huui</h1> */}
          {/* <Map1 /> */}
        </div>
      </div>

      <span className="copyright">
        Copyrights © 2022 Sun Agro Chemicals. All Rights Reserved.
      </span>
    </div>
  );
}

export default Footer;
