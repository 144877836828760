export const PGPList = [
  {
    _id: 1,
    Name: "Veera",
    imgurl: "ProductImg/Veera.png",
    TechnicalName: "Plant Growth Promoting Granuels",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane, Wheat.",
    Packing: "5Kg & 50Kg.",
    Usage:
      "Facilitates numerous plant metabolic activities ,better growth & development.",
  },
  {
    _id: 2,
    Name: "Black Gold",
    imgurl: "ProductImg/BG.png",
    TechnicalName: "Bio Potassium Humate",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane, Wheat, Vegetables.",
    Packing: "1kg & 500gm.",
    Usage:
      "Increase the efficiency of nitrogen- and phosphorus-based fertilizer inputs.",
  },
  {
    _id: 3,
    Name: "Volvo",
    imgurl: "ProductImg/Volvo.png",
    TechnicalName: "Organic Manure",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane.",
    Packing: "5kg & 25kg",
    Usage:
      "Controls stem borer & early shoot borer, promotes root development.",
  },

  {
    _id: 4,
    Name: "Top-Up",
    imgurl: "ProductImg/Top-Up.png",
    TechnicalName: "Organic Manure",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane, Wheat, Fruits, Vegetables.",
    Packing: "1Ltr,500ml,250ml.",
    Usage:
      "Provides the chelating action to the metals & helps in the transport by plant leaves.",
  },
  {
    _id: 5,
    Name: "Magic",
    imgurl: "ProductImg/Magic.png",
    TechnicalName: "Gibberellic Acid 0.001%L",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane, Wheat, Fruits, Vegetables.",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage:
      "Stimulate & regulate the plant growth, manipulate flowering & fruit development.",
  },
  {
    _id: 6,
    Name: "Alligent",
    imgurl: "ProductImg/Alligent.png",
    TechnicalName: "Ethephon 39% S.L",
    Type: "Plant Growth Promoter",
    Crops: "Pineapple, Mango, Tomato.",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Accelerates uniform ripening of fruits, improves coloration.",
  },
  {
    _id: 7,
    Name: "Virat",
    imgurl: "ProductImg/Virat.png",
    TechnicalName: "Mycorrhizal Biofertilizer",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane, Wheat, Fruits, Vegetables.",
    Packing: "5Kg.",
    Usage: "Allow plants to draw more nutrients and water from the soil.",
  },
  // {
  //   _id: 8,
  //   Name: "Sunflower",
  //   imgurl: "ProductImg/Sunflower.png",
  //   TechnicalName: "Nitrobenzene 20% E.W.",
  //   Type: "Plant Growth Promoter",
  //   Crops: "Paddy, Sugarcane, Wheat.",
  //   Packing: "5Kg & 50Kg.",
  //   Usage:
  //     "Facilitates numerous plant metabolic activities leading to better growth & development.",
  // },
  {
    _id: 8,
    Name: "Kala Sona",
    imgurl: "ProductImg/Kala Sona.jpg",
    TechnicalName: "Bio Liquid Extracts",
    Type: "Plant Growth Promoter",
    Crops: "Paddy, Sugarcane, Wheat, Fruits, Vegetables.",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage: "Promotes better growth & development of plant.",
  },
  {
    _id: 9,
    Name: "Sultan-25",
    imgurl: "ProductImg/sultan.png",
    TechnicalName: "PACLOBUTRAZOL 23% w/w (25% w/v) S.C",
    Type: "Plant Growth Regulator",
    Crops: "Mango, Paddy, Fruits.",
    Packing: "1Ltr, 500ml.",
    Usage: "Promotes fruit bud formation, flowering & improves fruit quality.",
  },
];
