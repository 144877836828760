import React from "react";
import "../../Styles/Card.css";
function ProductCard({
  id,
  imgurl,
  Name,
  Technicalname,
  Type,
  Crops,
  Packing,
  Usage,
}) {
  {
    console.log(Type);
  }
  return (
    <div
      className="Card "
      style={
        id % 2 == 0
          ? { backgroundColor: "#f8e8b7" }
          : { backgroundColor: "#bcf5e0" }
      }
    >
      <div className="img">
        <img
          src={imgurl}
          // style={
          //   Name == "Farsa" || Name == "Killer" || Name == "Black Tiger"
          //     ? { height: "300px" }
          //     : { height: "350px" }
          // }
          alt=""
        />
      </div>
      <div className="details">
        <h2>{Name}</h2>
        <img
          src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
          alt=""
        />
        <h3>{Technicalname}</h3> <span className="">{Type}</span>
        <div>
          <div className="prd">
            {" "}
            <b style={{ fontSize: "20px" }}>Crops: </b>
            {Crops}
          </div>
          <div className="prd">
            {" "}
            <b style={{ fontSize: "20px" }}>Uses: </b>
            {Usage}
          </div>

          <div className="prd">
            {" "}
            <b style={{ fontSize: "20px" }}>Packing: </b>
            {Packing}
          </div>
          {/* <div className="prd">
            {" "}
            <h6 className="span ">Dose per acre: </h6>
            <span> 5-6kg </span>
          </div> */}
          <br />
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
