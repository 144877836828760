export const FungicideList = [
  {
    _id: 1,
    Name: "Poshak",
    imgurl: "ProductImg/Poshak.png",
    TechnicalName: "Sulphur 90% WDG",
    Type: "Contact Fungicide",
    Crops: "Wheat, Paddy, Mango & Sugarcane",
    Packing: "30kg & 3kg.",
    Usage: "Helps in nodule development,chlorophyll formation",
  },
  {
    _id: 2,
    Name: "Chakra",
    imgurl: "ProductImg/Chakra.png",
    TechnicalName: "Carbendizum 50% W.P.",
    Type: "Systemic Fungicide",
    Crops: "Wheat, Paddy, Mango & Sugarcane",
    Packing: "500gm & 250gm.",
    Usage: "Controls leaf spot, rust diseases & blast disease of paddy crop",
  },
  {
    _id: 3,
    Name: "Cover",
    imgurl: "ProductImg/Cover.png",
    TechnicalName: "Carbendizum 12% + Mancozeb 63% W.P.",
    Type: "Systemic & Contact Fungicide",
    Crops: "Papaya, Bottle Gourd, Wheat, Paddy, Mango & Sugarcane",
    Packing: "500gm & 250gm.",
    Usage: "Controls leaf spot, rust diseases & blast disease of paddy crop",
  },
  {
    _id: 4,
    Name: "Dron",
    imgurl: "ProductImg/Dron.jpeg",
    TechnicalName: "Thiophanate Methyl 70% WP",
    Type: "Systemic  Fungicide",
    Crops: "Papaya, Bottle Gourd, Apple, Paddy, Mango & Sugarcane",
    Packing: "500gm & 250gm.",
    Usage: "Controls Anthracnose, Powdery Mildew, several leaf spot diseases.",
  },
  {
    _id: 5,
    Name: "Sulf Gold",
    imgurl: "ProductImg/Sulfgold.png",
    TechnicalName: "Sulphur 80% WDG",
    Type: "Contact  Fungicide",
    Crops: "Wheat, Paddy, Sugarcane, Maize,Cotton",
    Packing: "30kg & 3kg.",
    Usage: "Controls fungal spots, leaf spot, rust. ",
  },
  {
    _id: 6,
    Name: "Audi",
    imgurl: "ProductImg/audi.png",
    TechnicalName: "Azoxystrobin 11% + Tebuconazole 18.3% SC ",
    Type: "Systemic Fungicide",
    Crops: "Paddy, Wheat, Sugarcane, Mango, Chilli, Tomato, Potato",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage:
      "Prevents Sheath blight, Yellow rust, Early & late blight, Fruit Rot.",
  },
  {
    _id: 7,
    Name: "Audi-Top",
    imgurl: "ProductImg/Audi-Top.png",
    TechnicalName: "Azoxystrobin 18.2% + Difenoconazole 11.4%SC",
    Type: "systemic and curative broad-spectrum preventative fungicide",
    Crops: "Paddy, Wheat, Maize, Sugarcane, Mango, Chilli",
    Packing: "1Ltr, 500ml, 250ml, 100ml",
    Usage: "Protects the crop against invasion by fungal pathogens.",
  },
  {
    _id: 8,
    Name: "Azopro",
    imgurl: "ProductImg/azo-pro.png",
    TechnicalName: "Azoxystrobin 7.1% + Propiconazole 11.9% SE",
    Type: "Systemic antibiotic Fungicide",
    Crops: "Paddy, Wheat, Soyabean, Corn, Cauliflower, Cabbage",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage: "Protects the crop against invasion by fungal pathogens.",
  },
  {
    _id: 9,
    Name: "Valida Super",
    imgurl: "ProductImg/Valida.png",
    TechnicalName: "Validamycin 3%",
    Type: "Systemic antibiotic Fungicide",
    Crops: "Paddy, Wheat, Potato, Ginger",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage: "Prevent & treat sheath blight disease & other soil born disease.",
  },
  {
    _id: 10,
    Name: "Sixer",
    imgurl: "ProductImg/Sixer.png",
    TechnicalName: "Tebuconazole 38.39% SC",
    Type: "Systemic Fungicide",
    Crops: "Paddy, Wheat, Vegetables, Sugarcane",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage: "Control fungi, bacteria, and viruses affecting plants.",
  },
  {
    _id: 11,
    Name: "Kawach",
    imgurl: "ProductImg/Kawach.png",
    TechnicalName: "Hexaconazole 5% S.C.",
    Type: "Systemic  Fungicide",
    Crops: "Wheat, Paddy, Sugarcane, Groundnut, Chilli, Cotton",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Controls seed-borne & soil-borne disease.",
  },
  {
    _id: 12,
    Name: "Kabir",
    imgurl: "ProductImg/Kabir.png",
    TechnicalName: "Tebuconazole 8% + Captan 32% SC",
    Type: "Contact Fungicide",
    Crops: "Paddy, Wheat, Apple, Chilli",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Restrict the growth of the fungus,Chilli Anthracnose & fruit rot.",
  },
  {
    _id: 13,
    Name: "Sunfil",
    imgurl: "ProductImg/sunfil.jpg",
    TechnicalName: "Mancozeb 75% W.P",
    Type: "Contact Fungicide",
    Crops: "Wheat, Sugarcane, Paddy, Mango, Vegetables.",
    Packing: "500gm.",
    Usage:
      "Suitable for seed treatment and fungal diseases in crops such as flowers, vegetables, fruits.",
  },
  {
    _id: 14,
    Name: "Sun Shine",
    imgurl: "ProductImg/SunShine.png",
    TechnicalName: "Hexaconazole 5% S.C",
    Type: "Systemic  Fungicide",
    Crops: "Wheat, Paddy,Sugarcane, Maize, Ground Nut & Soybean",
    Packing: "1Ltr, 500ml, 250ml, 100ml.",
    Usage: "Controls powdery mildews, rusts, leafspots, scabs, & blights.",
  },
  {
    _id: 15,
    Name: "Sheru",
    imgurl: "ProductImg/sheru1.png",
    TechnicalName: "Tebuconazole 10% + sulphur 65% W.G",
    Type: "Systemic & Contact Fungicide",
    Crops: "Sugarcane, Paddy, Mango, Chilli, Soybean",
    Packing: "1kg, 500gm, 250gm.",
    Usage: "Prevents rust fungus, sheath blight, leaf spot, anthracnose.",
  },
  {
    _id: 16,
    Name: "Jammer",
    imgurl: "ProductImg/Jammer.png",
    TechnicalName: "Ziram 27% S.C.",
    Type: "Systemic & Contact Fungicide",
    Crops: "Paddy, Sugarcane, Potato, Tomato",
    Packing: "1Ltr, 500ml.",
    Usage:
      "Protects paddy crop from bakani, fruit and vegetable crops from fungal infection.",
  },
];
