import React from "react";
import "../Styles/Sec2.css";
function Sec2() {
  return (
    <div className=" Sec2">
      <div className="wlcm-div">
        <h3>Welcome To </h3> <h1>Sun Agro Chemicals!</h1>
        <img
          src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
          alt=""
        />
      </div>
      <div className="vision">
        <h3>
          Providing excellent Agrochemicals & Pesticides, Agricultural
          Pesticides, Sulphur Powder, Agricultural Sulphur, Sulphur WDG,
          Fertilizer, Fungicides, Growth Promoter, Herbicides, Etc. known for
          its high effectiveness and outstanding quality
        </h3>
      </div>
    </div>
  );
}

export default Sec2;
