import React from "react";
import "../Styles/About.css";
function Aboutus() {
  return (
    <div className="about" id="about">
      <div className="text">
        <div className="main">
          <h2>About Us</h2>
          <img
            src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
            alt=""
          />
        </div>
        <h3>
          We, Sun Agro Chemicals are an eminent manufacturer and supplier of
          Agrochemicals & Agricultural Pesticides.We are one of the India's most
          praised companies which offers a vast range of products, which
          includes Insecticide, Fungicides, Sulphur Powder, Sulphur WDG,
          Herbicide, Pesticide, Agricultural Pesticides, Weedicide, Household
          and Growth Promoters etc. All our products have gained lot of
          importance in the market for its high quality and high effectiveness.
          Our main aim is to provide quality agricultural products and chemicals
          to farmers.
        </h3>
        {/* <h3>
          Established in the year, 2009, we, Shree Ram Agro India are an eminent
          manufacturer and supplier of Agrochemicals & Agricultural Pesticides.
          We offer a vast line of products, which includes Insecticide,
          Fungicides, Sulphur Powder, Sulphur WDG, Herbicide, Pesticide,
          Agricultural Pesticides, Weedicide, Termiticide, Household and Growth
          Promoters etc. All our products have gained lot of importance in the
          market for its high quality and high effectiveness. Our company
          considers providing top most quality products as the foremost priority
          as we firmly believe that it is the kind of quality of products being
          supplied that shapes up a particular business in the long run
        </h3> */}
      </div>
      <div className="img">
        <img src="Images/Profile1.jpg" alt="" />
      </div>
    </div>
  );
}

export default Aboutus;
