import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../Styles/Home.css";
import Sec2 from "./Sec2";

import Aboutus from "./Aboutus";
import Popular2 from "./Popular2";
import Services from "./Services";
import Vision from "./Vision";
function Home() {
  return (
    <div>
      <div className="carousel">
        <Carousel
          showThumbs={false}
          autoPlay="true"
          interval="2000"
          S
          infiniteLoop="true"
          showArrows={false}
          transitionTime="1000"
          useKeyboardArrows={true}
        >
          <div>
            <img src="Images/sacCover4.jpg" alt="" />
          </div>
          <div>
            <img src="Images/sacCover1.jpg" alt="" />
          </div>
          <div>
            <img src="Images/sacCover3.jpg" alt="" />
          </div>
          {/* <div>
            <img src="Images/Cover3.JPG" alt="" />
          </div>
          <div>
            <img src="Images/Cover.JPG" alt="" />
          </div> */}

          <div>
            <img src="Images/sacCover5.jpg" alt="" />
          </div>
        </Carousel>
      </div>
      <Sec2 />
      {/* <Services /> */}

      <Aboutus />
      {/* <Vision /> */}
      <Popular2></Popular2>
    </div>
  );
}

export default Home;
