import React from "react";
import Carousel from "react-grid-carousel";
import "../Styles/Popular.css";
function Popular2() {
  return (
    <div className="popular2" id="popular">
      <div className="Top">
        <h1>Most Popular Products</h1>
        <img
          src="	https://tiimg.tistatic.com/catalogs/template102133/line.jpg
"
          alt=""
        />
        <h3>
          They have been acting as a motivating factor for our company as they
          appreciate the efforts made by our team from time to time.
        </h3>
      </div>
      <div className="carousel2">
        <Carousel
          cols={4}
          gap={40}
          loop
          showDots={true}
          autoplay={3000}
          transitionTime={1000}
          scrollSnap={true}
          responsiveLayout={[
            {
              breakpoint: 900,
              cols: 3,
              rows: 1,
              gap: 20,
              loop: true,
              autoplay: 3000,
            },
            {
              breakpoint: 800,
              gap: 0,
              cols: 2,
            },
            {
              breakpoint: 800,
              gap: 0,
              cols: 2,
            },
            {
              breakpoint: 760,
              autoplay: 1500,
              loop: true,
              showDots: true,
              gap: 100,
              rows: 2,
              cols: 1,
            },
          ]}
        >
          <Carousel.Item className="img21">
            <img style={{}} src="ProductImg/Volvo.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/BG.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Chakra.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Poshak.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            {" "}
            <img src="ProductImg/Virat.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Veera.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Maxx-Ultra.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Cover.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Sulfgold.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Rustam.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Tara_pro.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Sultan.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Top-Up.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/SunTara.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Rudra-44.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Thunder.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Audi.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Audi-Top.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/azo-pro.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Sixer.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Kohinoor.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Nagada.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Tiger-Sp.png" alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="ProductImg/Magic.png" alt="" />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Popular2;
