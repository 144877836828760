import React from "react";
import { FertiliserList } from "../List/FertiliserList";
import ProductCard from "./ProductCard";
import ProductPage from "./ProductPage";
function Fertlilizer() {
  return (
    <div>
      <ProductPage Title={"Fertilizer"} />
      {FertiliserList.map((item) => {
        return (
          <ProductCard
            id={item._id}
            imgurl={item.imgurl}
            Name={item.Name}
            Technicalname={item.Technicalname}
            Type={item.Type}
          />
        );
      })}
    </div>
  );
}

export default Fertlilizer;
