import React from "react";
import { PGPList } from "../List/PGPList";
import ProductCard from "./ProductCard";
import ProductPage from "./ProductPage";
function PGP() {
  return (
    <div>
      <ProductPage Title={"Plant Growth Promoter"} />
      {PGPList.map((item) => {
        return (
          <ProductCard
            id={item._id}
            imgurl={item.imgurl}
            Name={item.Name}
            Technicalname={item.TechnicalName}
            Type={item.Type}
            Crops={item.Crops}
            Usage={item.Usage}
            Packing={item.Packing}
          />
        );
      })}
    </div>
  );
}

export default PGP;
